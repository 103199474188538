import axios from "axios";

export const USERS_BASE_URL = process.env.REACT_APP_API_PATH + "/users";

const UserService = {
  async getUsers(searchCriteria) {
    const response = await axios.get(USERS_BASE_URL, {
      params: new URLSearchParams(searchCriteria),
    });
    return response.data;
  },
  async getUserById(userId) {
    const response = await axios.get(USERS_BASE_URL + "/" + userId);
    return response.data;
  },
  async createUser(user) {
    const response = await axios.post(USERS_BASE_URL, user);
    return response.data;
  },
  async updateUser(userId, user) {
    const response = await axios.put(USERS_BASE_URL + "/" + userId, user);
    return response.data;
  },
  async setDefaultBusiness(businessId, businessType) {
    const response = await axios.put(USERS_BASE_URL + "/defaultBusiness", {
      businessId,
      businessType,
    });
    return response.data;
  },
  async updateProfilePic(userId, profilePicFile) {
    const file = profilePicFile;
    const formData = new FormData();

    formData.append("file", file);
    const response = await axios.post(
      USERS_BASE_URL + "/" + userId + "/profilePic",
      formData
    );
    return response.data;
  },
  async getUsersByBusinessId(businessId) {
    const response = await axios.get(
      USERS_BASE_URL + "/business/" + businessId
    );
    return response.data;
  },
};

export default UserService;
