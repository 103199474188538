import { Container, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { LoadingOverlay } from "../../common/components/data-display/LoadingOverlay";
import { useAuthContext } from "../../common/context/AuthContext";
import { displayName } from "../../common/util/DisplayUtil";

const Dashboard = () => {
  const {
    currentUser,
    isMemberOfAnyBuyer,
    isMemberOfAnySupplier,
    isOwnerOfCollective,
    isMemberOfCollective,
  } = useAuthContext();
  const navigate = useNavigate();
  const [hasNoRole, setHasNoRole] = React.useState(false);

  React.useEffect(() => {
    // Take the people to their home page based on their role
    if (isOwnerOfCollective) {
      navigate("/app/business-admin");
    } else if (isMemberOfAnySupplier) {
      navigate("/app/supplier");
    } else if (isMemberOfCollective) {
      navigate("/app/business-admin");
    } else if (isMemberOfAnyBuyer) {
      navigate("/app/buyer");
    } else {
      setHasNoRole(true);
    }
  }, [
    isOwnerOfCollective,
    isMemberOfAnySupplier,
    isMemberOfCollective,
    isMemberOfAnyBuyer,
    navigate,
  ]);

  return (
    <Container>
      {hasNoRole && (
        <>
          <Typography variant="h1" gutterBottom>
            Welcome, {displayName(currentUser.firstName, currentUser.lastName)}
          </Typography>
        </>
      )}
      {!hasNoRole && <LoadingOverlay />}
    </Container>
  );
};

export default Dashboard;
