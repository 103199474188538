import { Button, Container, Skeleton, Typography } from "@mui/material";
import React from "react";
import MainAppBar from "../../common/components/MainAppBar";
import { useAlerts } from "../../common/context/AlertContext";
import { useAuthContext } from "../../common/context/AuthContext";
import { useCollective } from "../../common/context/CollectiveContext";
import { AuthenticationService } from "../../common/service/AuthenticationService";

const EmailNotVerifiedPage = () => {
  const { collectiveInfo } = useCollective();
  const { currentUser, logOut } = useAuthContext();
  const { addSuccessAlert, addErrorAlert } = useAlerts();

  return (
    <>
      <MainAppBar hideMenuButton={true} open={false} />
      <Container sx={{ mt: "20vh" }}>
        {collectiveInfo && currentUser ? (
          <>
            <Typography variant="h3" gutterBottom textAlign="center">
              Welcome to {collectiveInfo?.name}!
            </Typography>
            <Typography variant="body1" textAlign="center" gutterBottom>
              Please check your email (<b>{currentUser.email}</b>) to verify
              your account and get started. Verification email links expire
              after 48 hours.
            </Typography>
            <Typography
              variant="body1"
              textAlign="center"
              gutterBottom
              sx={{ mt: 5 }}
            >
              Didn't get a verification email?
            </Typography>
            <Button
              color="info"
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "fit-content",
                display: "block",
              }}
              variant="outlined"
              onClick={() =>
                AuthenticationService.resendVerificationEmail()
                  .then(() => addSuccessAlert("Verification email resent"))
                  .catch((err) => {
                    addErrorAlert("Error resending verification email", err);
                  })
              }
            >
              Resend verification email
            </Button>
            <Typography
              variant="body1"
              textAlign="center"
              gutterBottom
              sx={{ mt: 5 }}
            >
              Is <b>{currentUser.email}</b> not your email address?
            </Typography>
            <Button
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "fit-content",
                display: "block",
              }}
              variant="outlined"
              onClick={logOut}
            >
              Log out and sign in with a different email
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h3" gutterBottom textAlign="center">
              <Skeleton
                variant="rectangular"
                width="40%"
                sx={{ ml: "auto", mr: "auto" }}
              />
            </Typography>
            <Skeleton
              variant="text"
              width="60%"
              sx={{ ml: "auto", mr: "auto" }}
            />
            <Skeleton
              variant="text"
              width="60%"
              sx={{ ml: "auto", mr: "auto" }}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default EmailNotVerifiedPage;
