import { Button, Container, Typography } from "@mui/material";
import { FastField, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import BusinessAutocomplete from "../../../common/components/form/BusinessAutocomplete";
import FormCheckboxWithLabel from "../../../common/components/form/FormCheckboxWithLabel";
import FormColorInput from "../../../common/components/form/FormColorInput";
import { FormTextField } from "../../../common/components/form/FormTextField";
import CollectiveService from "../../../common/service/CollectiveService";

const EditCollective = ({ isNew }) => {
  const { collectiveId } = useParams();
  const navigate = useNavigate();
  const [collective, setCollective] = useState({
    name: "",
    subdomain: "",
    primaryColor: "",
    secondaryColor: "",
    business: null,
    active: false,
  });

  useEffect(() => {
    if (!isNew && collectiveId) {
      const fetchCollective = async () => {
        const data = await CollectiveService.getCollectiveById(collectiveId);
        const business = data.businessId
          ? {
              id: data.businessId,
              name: data.businessName,
              logoPath: data.logoPath,
            }
          : null;
        setCollective({ ...data, business });
      };
      fetchCollective();
    }
  }, [collectiveId, isNew]);

  const handleSubmit = async (values) => {
    if (isNew) {
      await CollectiveService.createCollective(values);
    } else {
      await CollectiveService.updateCollective(collectiveId, values);
    }
    navigate("/app/business-admin/manage-collectives");
  };

  return (
    <Container sx={{ pt: 1.5 }}>
      <Typography variant="h2" gutterBottom>
        {isNew ? "Add New Collective" : "Edit Collective"}
      </Typography>
      <Formik
        initialValues={collective}
        validationSchema={Yup.object({
          name: Yup.string().required("Required"),
          subdomain: Yup.string().required("Required"),
          primaryColor: Yup.string().required("Required"),
          secondaryColor: Yup.string().required("Required"),
          business: Yup.object().required("Required"),
        })}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FastField
              component={FormTextField}
              name="name"
              label="Collective Name"
              fullWidth
              required
              sx={{ mb: 2 }}
            />
            <FastField
              component={FormTextField}
              name="subdomain"
              label="Subdomain"
              fullWidth
              required
              autoCapitalize="off"
              helperText="Subdomain is used in the URL to access your collective. It must be unique across all collectives."
              sx={{ mb: 2 }}
            />
            <FastField
              component={FormColorInput}
              name="primaryColor"
              label="Primary Color"
              fullWidth
              required
              sx={{ mb: 2 }}
            />
            <FastField
              component={FormColorInput}
              name="secondaryColor"
              label="Secondary Color"
              fullWidth
              required
              sx={{ mb: 2 }}
            />
            <FastField
              component={BusinessAutocomplete}
              name="business"
              label="Business"
              fullWidth
              required
              ignoreCollectiveFilter
              sx={{ mb: 2 }}
            />
            <FastField
              component={FormCheckboxWithLabel}
              name="active"
              label="Active"
              sx={{ mb: 2 }}
            />
            <Button type="submit" variant="contained" color="primary">
              {isNew ? "Add Collective" : "Save Changes"}
            </Button>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default EditCollective;
