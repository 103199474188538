import { PhotoCamera, Save } from "@mui/icons-material";
import {
  Button,
  Card,
  CardMedia,
  CircularProgress,
  Container,
  Fab,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2.js";
import { FastField, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form, useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import TabPanel, {
  a11yTabProps,
} from "../../../common/components/data-display/TabPanel.jsx";
import AttributeFormField from "../../../common/components/form/AttributeFormField.jsx";
import CategoryFormSelect from "../../../common/components/form/CategoryFormSelect.jsx";
import FormCheckboxWithLabel from "../../../common/components/form/FormCheckboxWithLabel.jsx";
import { FormCurrencyField } from "../../../common/components/form/FormCurrencyField.jsx";
import FormSelect from "../../../common/components/form/FormSelect.jsx";
import { FormTextField } from "../../../common/components/form/FormTextField.jsx";
import { useAlerts } from "../../../common/context/AlertContext.jsx";
import { ALLOWED_IMG_TYPES } from "../../../common/enums/AllowedImageTypes.js";
import { DefaultUomOptions } from "../../../common/enums/DefaultUom.js";
import BaseProductService from "../../../common/service/BaseProductService.js";
import { displayName } from "../../../common/util/DisplayUtil.js";
import EditProductGroupPricing from "./EditProductGroupPricing.jsx";

const defaultBaseProductInfo = {
  name: "",
  description: "",
  active: true,
  productPicturePath: "",
  defaultQty: 1,
  defaultUom: "Unit",
  defaultPrice: "",
  attributes: [],
  categories: [],
  categoriesChanged: false,
};

const EditBaseProduct = () => {
  const [loading, setLoading] = useState(true);
  const [loadingProductImage, setLoadingProductImage] = useState(false);
  const navigate = useNavigate();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const formikRef = React.useRef();
  const [baseProductInfo, setBaseProductInfo] = useState(
    defaultBaseProductInfo
  );
  const location = useLocation();
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    location.state?.businessTab || 0
  );
  // Coerce baseproduct id to be numeric if it is a number
  let { baseProductId } = useParams();
  baseProductId = isNaN(baseProductId)
    ? baseProductId
    : parseInt(baseProductId);
  const isNew = baseProductId === "new";
  useEffect(() => {
    if (!baseProductId) {
      return;
    } else if (isNew) {
      setBaseProductInfo(defaultBaseProductInfo);
      setLoading(false);
    } else {
      fetchBaseProductInfo(baseProductId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseProductId]);

  async function fetchBaseProductInfo(baseproductId) {
    setLoading(true);
    try {
      const response =
        await BaseProductService.getBaseProductById(baseproductId);
      setBaseProductInfo(response);
      setLoading(false);
      formikRef.current?.setValues({ ...response });
    } catch (error) {
      addErrorAlert("Error fetching base product", error);
    }
  }

  const handleSubmit = async (baseproductInfoToSave) => {
    if (isNew) {
      return BaseProductService.createBaseProduct(baseproductInfoToSave)
        .then((createdBaseProduct) => {
          navigate(
            `/app/business-admin/manage-base-products/${createdBaseProduct.id}`,
            {
              replace: true,
            }
          );
          formikRef.current.resetForm({ values: createdBaseProduct });
          setBaseProductInfo(createdBaseProduct);
          addSuccessAlert("Product created");
        })
        .catch((error) => {
          addErrorAlert("Error creating product", error);
        });
    } else {
      return BaseProductService.updateBaseProduct(
        baseProductId,
        baseproductInfoToSave
      )
        .then((updatedBaseProduct) => {
          formikRef.current.resetForm({ values: updatedBaseProduct });
          setBaseProductInfo(updatedBaseProduct);
          addSuccessAlert("Changes saved");
        })
        .catch((error) => {
          addErrorAlert("Error saving changes", error);
        });
    }
  };

  const canEditCategories = isNew;
  return (
    <Container sx={{ pt: 1.5 }}>
      <Typography variant="h1" gutterBottom>
        {loading ? (
          <Skeleton />
        ) : isNew ? (
          "Add New Product"
        ) : (
          "Edit Product" +
          (baseProductInfo.name && ` - ${baseProductInfo.name}`)
        )}
      </Typography>
      <Tabs
        value={selectedTabIndex}
        onChange={(e, v) => {
          navigate(null, { state: { businessTab: v }, replace: true });
          setSelectedTabIndex(v);
        }}
        aria-label="business admin tabs"
      >
        <Tab label="Product Info" {...a11yTabProps(0)} />
        <Tab label={`Pricing`} {...a11yTabProps(1)} disabled={isNew} />
      </Tabs>
      <TabPanel value={selectedTabIndex} index={0}>
        <Formik
          initialValues={baseProductInfo}
          onSubmit={handleSubmit}
          innerRef={formikRef}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Required"),
            active: Yup.boolean().required("Required"),
            categories: Yup.array().required("Required"),
          })}
        >
          {(formikProps) => (
            <Form autoComplete="off">
              {loading ? (
                <>
                  <Skeleton variant="text" />
                  <Skeleton variant="rectangular" />
                  <Skeleton variant="rectangular" />
                </>
              ) : (
                <>
                  <Grid container spacing={4}>
                    <Grid
                      container
                      spacing={2}
                      xs={12}
                      md={7}
                      alignItems="start"
                    >
                      <Grid container spacing={2}>
                        <Grid xs={12}>
                          <Typography variant="h4">
                            Product Info
                            {baseProductId !== "new" &&
                              ` - ID: ${baseProductId}`}
                          </Typography>
                        </Grid>
                        <Grid xs={12} md={6}>
                          <FastField
                            component={FormTextField}
                            label="Name"
                            name="name"
                            autoCapitalize="words"
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <FastField
                            component={FormCheckboxWithLabel}
                            label="Active"
                            name="active"
                          />
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                          <FastField
                            component={FormSelect}
                            label="Sold by"
                            name="defaultUom"
                            options={DefaultUomOptions}
                          />
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                          <FastField
                            component={FormTextField}
                            label="Default Qty"
                            name="defaultQty"
                          />
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                          <FastField
                            component={FormCurrencyField}
                            label="Default Price"
                            name="defaultPrice"
                          />
                        </Grid>

                        <Grid xs={12} md={12}>
                          <FastField
                            component={FormTextField}
                            label="Description"
                            name="description"
                            multiline
                            rows={2}
                            required
                          />
                        </Grid>
                      </Grid>
                      <Grid xs={12} container>
                        {!isNew && (
                          <Grid xs={12}>
                            {loading ? (
                              <Skeleton
                                variant="rectangular"
                                sx={{ height: 400, width: 400, margin: "auto" }}
                              />
                            ) : (
                              <Card
                                sx={{
                                  maxWidth: 250,
                                  maxHeight: 250,
                                  width: 250,
                                  height: 250,
                                  position: "relative",
                                  margin: "auto",
                                }}
                              >
                                {baseProductInfo.productPicturePath && (
                                  <CardMedia
                                    id="product-image-display"
                                    component="img"
                                    image={baseProductInfo.productPicturePath}
                                    sx={{
                                      height: "100%",
                                      width: "100%",
                                    }}
                                    alt={baseProductInfo.name}
                                  />
                                )}
                                <Tooltip
                                  title="Select New Product Image"
                                  placement="top"
                                >
                                  <Fab
                                    size="small"
                                    sx={{
                                      position: "absolute",
                                      right: 4,
                                      bottom: 4,
                                    }}
                                    onClick={() =>
                                      document
                                        .getElementById(
                                          "change-product-image-btn"
                                        )
                                        .click()
                                    }
                                    disabled={loadingProductImage}
                                  >
                                    {loadingProductImage ? (
                                      <CircularProgress size="small" />
                                    ) : (
                                      <PhotoCamera />
                                    )}
                                  </Fab>
                                </Tooltip>
                                <input
                                  id={"change-product-image-btn"}
                                  type="file"
                                  accept="image/*"
                                  value=""
                                  hidden
                                  onChange={async (e) => {
                                    const file = e.target.files[0];
                                    if (
                                      !ALLOWED_IMG_TYPES.includes(file.type)
                                    ) {
                                      addErrorAlert(
                                        "Invalid file type. Please upload an image file."
                                      );
                                      return;
                                    }
                                    setLoadingProductImage(true);
                                    try {
                                      const updatebaseProductInfo =
                                        await BaseProductService.updateBaseProductImage(
                                          baseProductId,
                                          file
                                        );
                                      setBaseProductInfo({
                                        ...baseProductInfo,
                                        productPicturePath:
                                          // Image path may not change, so set the timestamp to guarantee refresh the image
                                          updatebaseProductInfo.productPicturePath +
                                          `?t=${new Date().getTime()}`,
                                      });
                                      addSuccessAlert("Product image updated");
                                    } catch (error) {
                                      addErrorAlert(
                                        "Error uploading image",
                                        error
                                      );
                                    } finally {
                                      setLoadingProductImage(false);
                                    }
                                  }}
                                />
                              </Card>
                            )}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      xs={12}
                      md={5}
                      flexDirection="column"
                      alignItems="start"
                    >
                      <Grid>
                        <Typography variant="h4">Attributes</Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Tooltip
                          title={
                            canEditCategories ? (
                              ""
                            ) : (
                              <Typography style={{ textAlign: "center" }}>
                                Categories cannot be changed for existing
                                products
                              </Typography>
                            )
                          }
                          placement="top-end"
                        >
                          <div>
                            <FastField
                              component={CategoryFormSelect}
                              name="categories"
                              onChange={(e, v) => {
                                formikProps.setFieldValue(
                                  "categoriesChanged",
                                  true
                                );
                                formikProps.handleChange(e, v);
                              }}
                              disabled={!canEditCategories}
                            />
                          </div>
                        </Tooltip>
                      </Grid>
                      {baseProductInfo?.attributes?.map((attr, i) =>
                        attr.instanceSpecific ? null : (
                          <Grid key={i} xs={12}>
                            <FastField
                              component={AttributeFormField}
                              attribute={attr}
                              name={`attributes[${i}].value`}
                            />
                          </Grid>
                        )
                      )}
                    </Grid>

                    {!isNew && (
                      <Grid container spacing={2} xs={12}>
                        <Grid xs={12}>
                          <Typography variant="body1">
                            Created by{" "}
                            {displayName(
                              baseProductInfo.createdByFirstName,
                              baseProductInfo.createdByLastName
                            )}{" "}
                            on{" "}
                            {moment(baseProductInfo.createdAt).format("LLLL")}
                          </Typography>
                        </Grid>
                        <Grid xs={12}>
                          <Typography variant="body1">
                            Updated by{" "}
                            {displayName(
                              baseProductInfo.updatedByFirstName,
                              baseProductInfo.updatedByLastName
                            )}{" "}
                            on{" "}
                            {moment(baseProductInfo.updatedAt).format("LLLL")}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Button
                    sx={{ m: 2 }}
                    variant="contained"
                    color="primary"
                    type="button"
                    startIcon={<Save />}
                    disabled={formikProps.isSubmitting}
                    onClick={formikProps.submitForm}
                  >
                    Save
                  </Button>
                </>
              )}
            </Form>
          )}
        </Formik>
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={1}>
        <EditProductGroupPricing baseProduct={baseProductInfo} />
      </TabPanel>
    </Container>
  );
};

export default EditBaseProduct;
