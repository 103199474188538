import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React from "react";
import { FormTextField } from "../../../common/components/form/FormTextField";
import { useAlerts } from "../../../common/context/AlertContext";
import SalesLogService from "../../../common/service/SalesLogService";
import { useSalesLogContext } from "./SalesLogContext";

export default function SalesLogEditNotesDialog({ open, onClose }) {
  const { salesLog, setSalesLog } = useSalesLogContext();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={{ notes: salesLog.notes }}
        onSubmit={async (values) => {
          return SalesLogService.updateSalesLog({
            ...salesLog,
            notes: values.notes,
          })
            .then((updatedSalesLog) => {
              setSalesLog(updatedSalesLog);
              addSuccessAlert("Note updated");
              onClose();
            })
            .catch((e) => addErrorAlert("Error updating sales log", e));
        }}
      >
        {() => (
          <Form autoComplete="off">
            <DialogTitle>Edit Notes</DialogTitle>
            <DialogContent>
              <Field
                name="notes"
                component={FormTextField}
                multiline
                rows={6}
                label="Notes"
                sx={{ mt: 1 }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} type="button">
                Cancel
              </Button>
              <Button type="submit" data-testid="save-notes-btn">
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
