import { Alert, CircularProgress, Paper, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import MainAppBar from "../../common/components/MainAppBar";
import { useAuthContext } from "../../common/context/AuthContext";
import { AuthenticationService } from "../../common/service/AuthenticationService";

export default function VerifyEmail() {
  const navigate = useNavigate();
  const { logInAsUser, currentUser, logOut } = useAuthContext();
  const [loading, setLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");
  let [searchParams] = useSearchParams();
  const verificationCode = searchParams.get("verificationCode");

  React.useEffect(() => {
    if (verificationCode) {
      AuthenticationService.verifyEmail(verificationCode)
        .then(logInAsUser)
        .catch((e) => {
          setErrorMessage(e.response?.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      if (!currentUser) {
        navigate("/");
      } else {
        navigate("/app");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationCode]);

  return (
    <>
      <MainAppBar hideMenuButton />
      <Paper
        elevation={3}
        sx={{
          flexDirection: "column",
          mt: "20vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: 300,
          marginLeft: "auto",
          marginRight: "auto",
          p: 3,
        }}
      >
        {loading && (
          <>
            <Typography variant="h3" gutterBottom textAlign="center">
              Verifying account...
            </Typography>
            <CircularProgress sx={{ marginTop: 3 }} />
          </>
        )}
        {errorMessage && (
          <>
            <Typography variant="h3" gutterBottom textAlign="center">
              There was a problem verifiying your account.
            </Typography>
            <Alert severity="warning" sx={{ marginTop: 3 }}>
              {errorMessage}
            </Alert>
            <Link
              to="/"
              onClick={() => {
                logOut();
              }}
            >
              Go back to login
            </Link>
          </>
        )}
      </Paper>
    </>
  );
}
