import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { FormTextField } from "../../common/components/form/FormTextField";
import { useAlerts } from "../../common/context/AlertContext";
import { useAuthContext } from "../../common/context/AuthContext";
import { AuthenticationService } from "../../common/service/AuthenticationService";
import NewPasswordRequirements, {
  PasswordRequirementSchema,
} from "./NewPasswordRequirements";
const ResetPasswordPage = () => {
  const { addErrorAlert } = useAlerts();
  const { logInAsUser } = useAuthContext();
  let [searchParams] = useSearchParams();
  const verificationCode = searchParams.get("verificationCode");
  const [showResetPasswordLink, setShowResetPasswordLink] =
    React.useState(false);
  const handleSubmit = async (values) => {
    return AuthenticationService.resetPasswordLogin(
      values.password,
      verificationCode
    )
      .then(logInAsUser) // Log in the user after resetting password
      .catch((err) => {
        if (err.response?.status === 410) {
          setShowResetPasswordLink(true);
        }
        addErrorAlert("Error resetting password", err);
      });
  };

  return (
    <Container maxWidth="xs">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Reset Password
        </Typography>
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            password: PasswordRequirementSchema,
            confirmPassword: Yup.string()
              .oneOf([Yup.ref("password"), null], "Passwords must match")
              .required("Confirm Password is required"),
          })}
          validateOnChange={false}
          validateOnBlur={true}
        >
          {({ values, isSubmitting }) => (
            <Form style={{ width: "100%", marginTop: "24px" }}>
              <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={12}>
                  <Field
                    component={FormTextField}
                    autoFocus
                    name="password"
                    variant="outlined"
                    label="Password"
                    type="password"
                    size="medium"
                    required
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={FormTextField}
                    name="confirmPassword"
                    variant="outlined"
                    label="Confirm Password"
                    type="password"
                    size="medium"
                    required
                    autoComplete="new-password"
                  />
                </Grid>
                {showResetPasswordLink && (
                  <Grid item>
                    <Alert severity="error">
                      The password reset email may have expired. Please request
                      a new password reset link by clicking the button below.
                      <Link to="/forgot-password">
                        <Typography>Request new password reset link</Typography>
                      </Link>
                    </Alert>
                  </Grid>
                )}
                <Grid item>
                  <NewPasswordRequirements passwordToTest={values.password} />
                </Grid>
                <Grid item>
                  <Button
                    id="reset-pw-button"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress
                          size="small"
                          style={{ height: 16, width: 16 }}
                          color="inherit"
                        />
                      ) : undefined
                    }
                  >
                    {isSubmitting ? "Resetting password..." : "Reset Password"}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};

export default ResetPasswordPage;
