import { BusinessCenter, Delete, MoreVert } from "@mui/icons-material";
import {
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../common/context/AuthContext";
import { displayName } from "../../../common/util/DisplayUtil";

export const BusinessUserRoles = [
  {
    value: "OWNER",
    label: "Owner",
  },
  {
    value: "MANAGER",
    label: "Manager",
  },
  {
    value: "ASSOCIATE",
    label: "Associate",
  },
];

const BusinessUserSummary = ({
  businessUser,
  showBusinessInfo,
  showUserInfo,
  editBusinessUser,
  deleteBusinessUser,
  ...props
}) => {
  const { isMemberOfCollective, isMemberOfBusiness } = useAuthContext();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorEl);
  const [role, setRole] = useState(businessUser.role);

  return (
    <Box
      component="li"
      sx={{
        mb: 0.125,
        p: 0.5,
        width: "100%",
        display: "flex",
        maxWidth: 500,
        alignItems: "center",
      }}
      data-testid={"business-user-summary"}
      {...props}
    >
      {showBusinessInfo && (
        <>
          <Avatar
            src={businessUser.logoPath}
            alt={businessUser.name}
            sx={{ height: 30, width: 30 }}
          >
            <BusinessCenter />
          </Avatar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              minWidth: 0,
              ml: 2,
              mr: 0,
            }}
          >
            <Typography
              sx={{
                textOverflow: "ellipsis",
                overflowX: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {isMemberOfCollective ||
              isMemberOfBusiness(businessUser.businessId) ? (
                <Link
                  to={`/app/business-admin/manage-businesses/${businessUser.businessId}`}
                >
                  {businessUser.name}
                </Link>
              ) : (
                businessUser.name
              )}
            </Typography>
            <Typography
              sx={{
                textOverflow: "ellipsis",
                overflowX: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {businessUser.email}
            </Typography>
          </Box>
        </>
      )}
      {showUserInfo && (
        <>
          <Avatar
            src={businessUser.profilePicPath}
            alt={displayName(businessUser.firstName, businessUser.lastName)}
            sx={{ height: 30, width: 30 }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              ml: 2,
            }}
          >
            <Typography
              sx={{
                textOverflow: "ellipsis",
                overflowX: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              <Link
                to={`/app/business-admin/manage-users/${businessUser.userId}`}
              >
                {displayName(businessUser.firstName, businessUser.lastName)}
              </Link>
            </Typography>
            <Typography
              sx={{
                textOverflow: "ellipsis",
                overflowX: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {businessUser.email}
            </Typography>
          </Box>
        </>
      )}
      {editBusinessUser && (
        <Select
          data-testid="business-user-role-select"
          name="businessUserRole"
          size="small"
          value={role}
          onChange={(e) => {
            setRole(e.target.value);
            editBusinessUser({ ...businessUser, role: e.target.value });
          }}
          sx={{ ml: 2 }}
        >
          {BusinessUserRoles.map((role) => (
            <MenuItem key={role.value} value={role.value}>
              {role.label}
            </MenuItem>
          ))}
        </Select>
      )}
      {!editBusinessUser && (
        <Typography sx={{ ml: 2 }}>{businessUser.role}</Typography>
      )}
      {deleteBusinessUser && (
        <Box>
          <IconButton
            aria-label="more actions"
            id={`more-actions-menu-button-user-${businessUser.userId}`}
            aria-controls={menuOpen ? "more-actions-menu" : undefined}
            aria-expanded={menuOpen ? "true" : undefined}
            aria-haspopup="true"
            onClick={(e) => setMenuAnchorEl(e.currentTarget)}
          >
            <MoreVert />
          </IconButton>
          <Menu
            id="more-actions-menu"
            anchorEl={menuAnchorEl}
            open={menuOpen}
            onClose={() => setMenuAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": `more-actions-menu-button-user-${businessUser.userId}`,
            }}
          >
            <MenuItem
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure you want to remove this user from this business?"
                  )
                ) {
                  deleteBusinessUser(businessUser);
                }
              }}
            >
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              <ListItemText primary="Remove User" />
            </MenuItem>
          </Menu>
        </Box>
      )}
    </Box>
  );
};

export default BusinessUserSummary;
