import { NotInterested, ReceiptLong } from "@mui/icons-material";
import { Box, ListItemButton, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import BusinessDisplay from "../../../common/components/data-display/BusinessDisplay";
import TypographyEllipsis from "../../../common/components/data-display/TypographyEllipsis";
import UserDisplay from "../../../common/components/data-display/UserDisplay";
import { displayPrice } from "../../../common/util/DisplayUtil";

export function AdminPresaleOrderSummary({ ...props }) {
  return <PresaleOrderSummary {...props} adminView />;
}

export default function PresaleOrderSummary({
  presaleOrder,
  item,
  adminView = false,
  ...props
}) {
  const order = presaleOrder || item;
  let linkProps = {};
  if (!props.onClick) {
    linkProps = {
      LinkComponent: Link,
      to:
        `/app/buyer/presale/order/${order.id}` +
        (order.fulfillmentStatus === "DRAFT" ? "/edit" : ""),
    };
  }
  return (
    <ListItemButton
      sx={{
        mb: 0.25,
        p: 1,
        width: "100%",
        display: "flex",
        alignItems: "center",
        borderRadius: 1,
        border: "1px solid #E0E0E0",
        gap: 1,
      }}
      {...linkProps}
      aria-label={`View order PS${order.id ?? ` for ${moment(order.saleDate).format("M/D/YY")}`}`}
      {...props}
      data-testid={"presale-order-summary"}
    >
      <Box
        sx={{
          display: "flex",
          minWidth: 0,
          flexWrap: "wrap",
          gap: 2,
          width: "100%",
          alignItems: "center",
        }}
      >
        {adminView && (
          <Box sx={{}}>
            {Boolean(order.isLinkedToInvoice) && (
              <Tooltip title="Linked to an invoice">
                <ReceiptLong color="info" />
              </Tooltip>
            )}
            {Boolean(!order.isLinkedToInvoice) && (
              <Tooltip title="Not linked to an invoice">
                <NotInterested />
              </Tooltip>
            )}
          </Box>
        )}
        <Box sx={{ display: "flex", flexDirection: "column", width: 100 }}>
          <Typography variant="caption">Sale Date</Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: 800, display: "inline-block" }}
            data-testid="order-sale-date"
          >
            {moment(order.saleDate).format("ddd, M/D/YY")}
          </Typography>
        </Box>
        {
          <BusinessDisplay
            business={order.buyerBusiness}
            label="Buyer"
            sx={{ width: 200 }}
          />
        }
        <UserDisplay
          user={order.createdBy}
          label="Order Placed By"
          sx={{ width: 180 }}
        />

        {order.pickupLocation?.name && (
          <Box sx={{ display: "flex", flexDirection: "column", width: 130 }}>
            <TypographyEllipsis variant="caption">
              Pickup Option
            </TypographyEllipsis>
            <TypographyEllipsis
              variant="body1"
              sx={{ display: "inline-block" }}
              data-testid="order-sale-date"
            >
              {order.pickupLocation.name}
            </TypographyEllipsis>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
            width: "fit-content",
          }}
        >
          <Typography variant="caption" sx={{ alignSelf: "flex-end" }}>
            Est. Total
          </Typography>
          <Typography
            variant="body1"
            sx={{
              alignSelf: "flex-end",
              fontWeight: 800,
              width: 120,
              display: "inline-block",
              textAlign: "end",
              marginLeft: "auto",
            }}
            data-testid="order-estimated-total"
          >
            {displayPrice(order.total)}
          </Typography>
        </Box>
      </Box>
    </ListItemButton>
  );
}
