import { Check, Close } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React from "react";
import * as Yup from "yup";

export const PasswordRequirementSchema = Yup.string()
  .label("Password")
  .required("Password is required")
  .test(
    "has-lowercase",
    "Password must contain a lowercase letter",
    (value) => {
      if (!value) {
        return true;
      }
      return /[a-z]/.test(value);
    }
  )
  .test(
    "has-uppercase",
    "Password must contain an uppercase letter",
    (value) => {
      if (!value) {
        return true;
      }
      return /[A-Z]/.test(value);
    }
  )
  .test("has-number", "Password must contain a number", (value) => {
    if (!value) {
      return true;
    }
    return /[0-9]/.test(value);
  })
  .test(
    "has-special-char",
    "Password must contain a special character",
    (value) => {
      if (!value) {
        return true;
      }
      return /[^A-Za-z0-9]/.test(value);
    }
  )
  .min(8, "Password must be at least 8 characters");

export default function NewPasswordRequirements({ passwordToTest }) {
  const passwordReqs = [
    {
      name: "At least 8 characters",
      regex: /.{8,}/,
    },
    {
      name: "At least one uppercase letter",
      regex: /[A-Z]/,
    },
    {
      name: "At least one lowercase letter",
      regex: /[a-z]/,
    },
    {
      name: "At least one number",
      regex: /\d/,
    },
    {
      name: "At least one special character",
      regex: /[^A-Za-z0-9]/,
    },
  ];

  return (
    <ul style={{ listStyle: "none", padding: 8 }}>
      {passwordReqs.map((req, i) => (
        <li key={i} style={{ display: "flex", alignItems: "center" }}>
          {req.regex.test(passwordToTest) ? (
            <Check
              color={"success"}
              sx={{ display: "inline-block", height: 14, width: 14 }}
            />
          ) : (
            <Close
              color={"disabled"}
              sx={{ display: "inline-block", height: 14, width: 14 }}
            />
          )}
          <Typography variant="body2" sx={{ ml: 1, display: "inline-block" }}>
            {req.name}
          </Typography>
        </li>
      ))}
    </ul>
  );
}
