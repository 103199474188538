import {
  AttachMoney,
  AutoGraph,
  ManageAccounts,
  ShoppingBag,
  Yard,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { Link, Outlet } from "react-router-dom";
import { useAuthContext } from "../common/context/AuthContext";
import { useCollective } from "../common/context/CollectiveContext";
import ProfileMenu from "./ProfileMenu"; // Assuming ProfileMenu is in the same directory

const NAV_DRAWER_WIDTH = "240px";
const NavDrawerContent = ({ menuItems, onClickLink }) => (
  <List>
    {menuItems
      .filter((item) => item.canView)
      .map((navItem) => (
        <ListItem
          key={navItem.displayText}
          disablePadding
          sx={{ display: "block" }}
          style={navItem.style}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: "initial",
              px: 2.5,
            }}
            component={Link}
            to={navItem.linkTo}
            aria-label={navItem.displayText}
            onClick={onClickLink}
          >
            <ListItemIcon sx={{ minWidth: 0, mr: 3, justifyContent: "center" }}>
              {navItem.icon}
            </ListItemIcon>
            <ListItemText
              primary={navItem.displayText}
              sx={{ opacity: 1, color: "white" }}
            />
          </ListItemButton>
        </ListItem>
      ))}
  </List>
);

export default function AuthenticatedSiteLayout() {
  const { isMemberOfCollective, isMemberOfAnyBuyer, isMemberOfAnySupplier } =
    useAuthContext();
  const { collectiveInfo } = useCollective();
  const theme = useTheme();
  const showPermanentDrawer = useMediaQuery(theme.breakpoints.up("lg"));
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const menuItems = [
    {
      linkTo: "/app/buyer",
      displayText: "Buyer Home",
      icon: <ShoppingBag />,
      canView: isMemberOfAnyBuyer,
    },
    {
      linkTo: "/app/supplier",
      displayText: "Supplier Home",
      icon: <Yard />,
      canView: isMemberOfAnySupplier,
    },
    {
      linkTo: "/app/business-admin",
      displayText: "Administration",
      icon: <ManageAccounts />,
      canView: isMemberOfCollective,
    },
    {
      linkTo: "/app/reporting",
      displayText: "Reports",
      icon: <AutoGraph />,
      canView:
        isMemberOfCollective || isMemberOfAnySupplier || isMemberOfAnyBuyer,
    },
    {
      linkTo: "/app/point-of-sale",
      displayText: "Sales Checkout",
      icon: <AttachMoney />,
      canView: isMemberOfCollective,
    },
  ];

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        id="main-app-bar"
      >
        <Toolbar>
          {!showPermanentDrawer && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Link to="/">
            <Typography
              variant="h1"
              noWrap
              sx={{
                width: "fit-content",
                display: "inline-block",
                color: "#fff",
                textDecoration: "none",
              }}
            >
              {collectiveInfo?.name}
            </Typography>
          </Link>
          {showPermanentDrawer && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginLeft: "auto",
                textAlign: "right",
              }}
            >
              <Typography variant="body2">Powered by </Typography>
              <img
                src="/img/picklinq-solutions/SVG/Picklinq_Horizontal_White.svg"
                alt="Picklinq"
                style={{ height: 48, marginLeft: 10 }}
              />
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant={showPermanentDrawer ? "permanent" : "temporary"}
          open={drawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            // On large screens, add some margin to the top to account for the app bar
            "& .MuiDrawer-paper": {
              paddingTop: { xs: 7, sm: 8 },
              width: NAV_DRAWER_WIDTH,
              boxSizing: "border-box",
            },
          }}
        >
          <NavDrawerContent
            menuItems={menuItems}
            onClickLink={() => setDrawerOpen(false)}
          />
          <Box sx={{ flexGrow: 1 }} />
          <ProfileMenu drawerOpen={drawerOpen} />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: "auto",
          height: "calc(100% - 64px)",
          mt: { xs: 7, sm: 8 },
          ml: showPermanentDrawer ? NAV_DRAWER_WIDTH : 0,
          position: "relative",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}
