import { Clear } from "@mui/icons-material";
import { Box, Button, Paper } from "@mui/material";
import { Field } from "formik";
import React from "react";
import BusinessAutocomplete from "../../common/components/form/BusinessAutocomplete";
import DebouncedTextField from "../../common/components/form/DebouncedTextField";
import ProductInstanceFilter from "../../common/components/form/ProductInstanceFilter";
import SearchCriteriaColorPicker from "../../common/components/form/SearchCriteriaColorPicker";

export default function PointOfSaleInventorySearchCriteria({ clearFilters }) {
  return (
    <Paper
      sx={{ top: 0, p: 1, zIndex: 1 }}
      elevation={0}
      data-testid="point-of-sale-inventory-search-criteria"
    >
      <Box
        sx={{ display: "flex", gap: 2, flexWrap: "wrap", alignItems: "center" }}
      >
        <Field
          component={DebouncedTextField}
          label="Search..."
          name="searchText"
          sx={{ maxWidth: 220, width: "100%" }}
          size="small"
        />
        <Field
          as={SearchCriteriaColorPicker}
          label="Color"
          name="colors"
          size="small"
        />
        <Field
          component={BusinessAutocomplete}
          name="supplier"
          label="Supplier"
          suppliersOnly
          sx={{ maxWidth: 300, width: "100%" }}
        />
        <Field
          as={ProductInstanceFilter}
          name="specificProductInstance"
          label="Product"
        />
        <Field
          as={ProductInstanceFilter}
          name="advancedProductDisplay"
          label="Product"
        />
        <Button
          onClick={clearFilters}
          size="medium"
          variant="outlined"
          color="error"
          startIcon={<Clear />}
          sx={{ marginLeft: "auto" }}
        >
          Clear Filters
        </Button>
      </Box>
    </Paper>
  );
}
