import { Delete, Forward } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import TermsConditionsSignDialog from "../../../common/components/dialogs/TermsConditionsSignDialog";
import { ProductInstanceSummary } from "../../../common/components/product/ProductInstanceSummary";
import ProductQuantityChip from "../../../common/components/product/ProductQuantityChip";
import { useAlerts } from "../../../common/context/AlertContext";
import PresaleOrderService from "../../../common/service/PresaleOrderService";
import { displayPrice } from "../../../common/util/DisplayUtil";
import CartTimer from "./CartTimer";
import { usePresaleOrderContext } from "./PresaleOrderFormContext";

function DeleteProductButton({ product, index, ...props }) {
  const { presaleOrder, setPresaleOrder } = usePresaleOrderContext();
  const { addConfirmAlert, addSuccessAlert } = useAlerts();
  return (
    <IconButton
      color="error"
      onClick={(e) => {
        e.stopPropagation();
        addConfirmAlert({
          message: "Do you want to remove this item from your cart?",
          onConfirm: () =>
            PresaleOrderService.deletePresaleOrderItem(
              presaleOrder.id,
              product.presaleOrderItemId
            ).then(() => {
              presaleOrder.orderProducts.splice(index, 1);
              setPresaleOrder({
                ...presaleOrder,
                total:
                  presaleOrder.total - product.unitPrice * product.quantity,
              });
              addSuccessAlert("Item removed from cart");
            }),
          onReject: () => {},
        });
      }}
      {...props}
    >
      <Delete />
    </IconButton>
  );
}

export default function PresaleCart({ view, onClickCartItem }) {
  const navigate = useNavigate();
  const { addErrorAlert, addSuccessAlert, addWarningAlert } = useAlerts();
  const { presaleOrder, setPresaleOrder } = usePresaleOrderContext();
  const [submitting, setSubmitting] = React.useState(false);
  const summaryComponents =
    view === "review"
      ? [
          ProductQuantityChip,
          ({ product }) => (
            <Typography sx={{ mr: 1 }}>
              {displayPrice(product.unitPrice)}
            </Typography>
          ),
          ({ product }) => (
            <Typography sx={{ ml: 2 }} fontWeight="bold">
              {displayPrice(product.unitPrice * product.quantity)}
            </Typography>
          ),
        ]
      : [ProductQuantityChip];

  const [showTermsConditions, setShowTermsConditions] = React.useState(false);

  async function placeOrder() {
    setSubmitting(true);
    return PresaleOrderService.submitOrder(presaleOrder)
      .then((response) => {
        setPresaleOrder(response);
        addSuccessAlert("Order placed successfully");
        navigate("/app/buyer/presale/order/" + presaleOrder.id);
      })
      .catch((e) => {
        addErrorAlert(e.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <Box
      data-testid={"presale-cart"}
      sx={{
        position: "relative",
        width: "100%",
        overflowY: "hidden",
        height: "100%",
        maxHeight: {
          xs: "100vh",
          // for sm and up, subtract the height of the app bar
          sm: `calc(100vh - 64px)`,
        },
        borderLeft: {
          xs: "none",
          sm: "1px solid",
        },
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CartTimer sx={{ flexShrink: 0 }} />
      <Box sx={{ flexGrow: 1, width: "100%", height: 1, overflowY: "auto" }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ marginLeft: "auto", marginRight: "auto" }}
        >
          Cart
        </Typography>
        {presaleOrder.orderProducts.length === 0 ? (
          <Typography variant="body1" sx={{ p: 1 }}>
            Your cart is empty
          </Typography>
        ) : (
          presaleOrder.orderProducts.map((product, index) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              key={product.presaleOrderItemId + "-" + product.quantity}
            >
              <ProductInstanceSummary
                productInstance={product}
                summaryComponents={summaryComponents}
                onClick={
                  onClickCartItem
                    ? () => {
                        onClickCartItem(product);
                      }
                    : undefined
                }
              />
              {view === "shopping" && (
                <DeleteProductButton
                  product={product}
                  index={index}
                  sx={{ height: "fit-content" }}
                />
              )}
            </Box>
          ))
        )}
        <Paper
          data-testid="estimated-total-container"
          sx={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
            borderTop: 1,
            borderColor: "divider",
            borderRadius: 0,
            p: 2,
            textAlign: "end",
            zIndex: 1,
            mt: 0.5,
          }}
          elevation={0}
        >
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" data-testid="cart-item-count">
              Quantity:{" "}
              {presaleOrder.orderProducts?.reduce(
                (acc, item) => acc + item.quantity,
                0
              )}
            </Typography>
            <Typography variant="h6" data-testid="cart-subtotal">
              Est. Total: {displayPrice(presaleOrder.total)}
            </Typography>
          </Box>
        </Paper>
      </Box>
      {view === "shopping" && (
        <Button
          color="success"
          sx={{
            borderRadius: 0,
            fontSize: "120%",
            flex: "none",
          }}
          variant="contained"
          size="large"
          fullWidth
          component={Link}
          to="/app/buyer/presale/review"
          endIcon={<Forward />}
          onClick={(e) => {
            if (!presaleOrder.orderProducts?.length) {
              addWarningAlert(
                "Please add items to your cart before proceeding"
              );
              e.preventDefault();
              return;
            }
            if (!presaleOrder.buyerBusiness?.id) {
              addWarningAlert("Please select a buyer business");
              e.preventDefault();
              return false;
            }
            if (!presaleOrder.pickupLocation?.id) {
              addWarningAlert("Please select a pickup option");
              e.preventDefault();
              return false;
            }
            return true;
          }}
        >
          Review your order
        </Button>
      )}
      {view === "review" && (
        <Button
          color="success"
          sx={{
            borderRadius: 0,
            fontSize: "120%",
            flex: "none",
            width: "fit-content",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          variant="contained"
          size="large"
          disabled={!presaleOrder.orderProducts?.length || submitting}
          startIcon={submitting ? <CircularProgress size={24} /> : null}
          onClick={() => {
            if (!presaleOrder.buyerBusiness?.id) {
              addWarningAlert("Please select a buyer business");
              return;
            }
            setShowTermsConditions(true);
          }}
        >
          Place Order
        </Button>
      )}

      <TermsConditionsSignDialog
        open={showTermsConditions}
        onClose={() => setShowTermsConditions(false)}
        onSign={placeOrder}
        typeOfTerms="buyer"
      />
    </Box>
  );
}
