import { Edit } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Container,
  Divider,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import BusinessDisplay from "../../common/components/data-display/BusinessDisplay";
import UserDisplay from "../../common/components/data-display/UserDisplay";
import ProductAvailabilityIndicator from "../../common/components/product/ProductAvailabilityIndicator";
import { ProductInstanceSummary } from "../../common/components/product/ProductInstanceSummary";
import ProductQuantityWithFulfillmentChip from "../../common/components/product/ProductQuantityWithFulfillmentChip";
import { useAlerts } from "../../common/context/AlertContext";
import AdvancedRequestService from "../../common/service/AdvancedRequestService";
import {
  displayAdvancedRequestName,
  displayPrice,
} from "../../common/util/DisplayUtil";
import { useAdvancedRequestContext } from "./AdvancedRequestFormContext";
import AdvancedRequestsBreadcrumbs from "./AdvancedRequestsBreadcrumbs";
import AttachmentPreviewDialog from "./AttachmentPreviewDialog";

export default function AdvancedRequestDetails() {
  const { addErrorAlert } = useAlerts();
  const { canEdit, advancedRequest, setAdvancedRequest, adminView } =
    useAdvancedRequestContext();
  const [selectedAttachmentIdx, setSelectedAttachmentIdx] =
    React.useState(null);
  return (
    <Container sx={{ pt: 1.5 }}>
      <AdvancedRequestsBreadcrumbs
        selectedRequest={advancedRequest}
        adminView={adminView}
      />
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Typography variant="h3">
          Request - {displayAdvancedRequestName(advancedRequest)}
        </Typography>
        {advancedRequest.fulfillmentStatus === "DRAFT" && (
          <Box sx={{ display: "inline-block" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Alert severity="warning">
                This request is a draft and has not been submitted
              </Alert>
            </Box>
          </Box>
        )}
        {canEdit && (
          <Button
            LinkComponent={Link}
            to={
              adminView
                ? `/app/business-admin/manage-request/${advancedRequest.id}/edit`
                : `/app/buyer/product-requests/${advancedRequest.id}/edit`
            }
            variant="outlined"
            startIcon={<Edit />}
          >
            {advancedRequest.fulfillmentStatus === "DRAFT"
              ? "Continue Editing Request"
              : "Edit Request"}
          </Button>
        )}
      </Box>
      <Divider sx={{ m: 2 }} />
      <Grid container spacing={2} alignItems="start">
        <Grid
          xs={12}
          md={5}
          container
          spacing={1.5}
          alignItems="start"
          justifyContent="start"
        >
          <Grid xs={12} container flexWrap="nowrap">
            <Grid sx={{ width: 100, minWidth: 100 }}>
              <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
                Pickup Date:
              </Typography>
            </Grid>
            <Grid sx={{ flexGrow: 1 }}>
              <Typography variant="body1">
                {moment(advancedRequest.pickupDate).format("LL")}
              </Typography>
            </Grid>
          </Grid>

          <Grid xs={12} container flexWrap="nowrap">
            <Grid sx={{ width: 100, minWidth: 100 }}>
              <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
                Ordered By:
              </Typography>
            </Grid>
            <Grid sx={{ flexGrow: 1 }}>
              <UserDisplay
                user={advancedRequest.createdBy}
                isLink={Boolean(adminView)}
              />
            </Grid>
          </Grid>
          <Grid xs={12} container flexWrap="nowrap">
            <Grid sx={{ width: 100, minWidth: 100 }}>
              <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
                Business:
              </Typography>
            </Grid>
            <Grid sx={{ flexGrow: 1 }}>
              <BusinessDisplay
                business={advancedRequest.buyerBusiness}
                isLink={Boolean(adminView)}
              />
            </Grid>
          </Grid>
          <Grid xs={12} container flexWrap="nowrap">
            <Grid sx={{ width: 100, minWidth: 100 }}>
              <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
                Pickup:
              </Typography>
            </Grid>
            <Grid sx={{ flexGrow: 1 }}>
              <Typography variant="body1">
                {advancedRequest.pickupLocation ? (
                  advancedRequest.pickupLocation.name
                ) : (
                  <Typography variant="body1">Unknown</Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} container flexWrap="nowrap">
            <Grid sx={{ width: 100, minWidth: 100 }}>
              <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
                Notes:
              </Typography>
            </Grid>
            <Grid sx={{ flexGrow: 1 }}>
              <Typography variant="body1">{advancedRequest.notes}</Typography>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <Divider />
          </Grid>
          <Grid xs={12} container flexWrap="nowrap">
            <Grid sx={{ width: 100, minWidth: 100 }}>
              <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
                Total (est.):
              </Typography>
            </Grid>

            <Grid sx={{ flexGrow: 1 }}>
              <Typography variant="body1">
                {displayPrice(advancedRequest.estimatedTotal)}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <Divider />
          </Grid>
          <Grid xs={12} container>
            {advancedRequest.attachments.map((attachment, idx) => (
              <Grid xs={6} sm={3} key={attachment.id}>
                <Card>
                  <CardActionArea onClick={() => setSelectedAttachmentIdx(idx)}>
                    <CardMedia
                      component="img"
                      src={attachment.attachmentPath}
                    />
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
            <AttachmentPreviewDialog
              open={selectedAttachmentIdx !== null}
              onClose={() => setSelectedAttachmentIdx(null)}
              imageUrl={
                advancedRequest.attachments?.[selectedAttachmentIdx]
                  ?.attachmentPath
              }
              dialogTitle={`Reference Image ${selectedAttachmentIdx + 1}`}
              onClickNext={
                advancedRequest.attachments.length > 1
                  ? () => {
                      setSelectedAttachmentIdx(
                        (selectedAttachmentIdx + 1) %
                          advancedRequest.attachments.length
                      );
                    }
                  : undefined
              }
              onClickPrev={
                advancedRequest.attachments.length > 1
                  ? () => {
                      setSelectedAttachmentIdx(
                        (selectedAttachmentIdx -
                          1 +
                          advancedRequest.attachments.length) %
                          advancedRequest.attachments.length
                      );
                    }
                  : undefined
              }
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          data-testid="request-product-list"
          sx={{ mt: 1 }}
          xs={12}
          md={7}
        >
          <Grid xs={12}>
            <Typography variant="h5" gutterBottom>
              Requested Products
            </Typography>
          </Grid>
          {advancedRequest?.requestProducts.length === 0 && (
            <Grid xs={12}>
              <Typography variant="body1">
                No products have been added to this request yet.
              </Typography>
            </Grid>
          )}
          {advancedRequest?.requestProducts.map((product, index) => (
            <Grid
              xs={12}
              key={index}
              container
              flexWrap="nowrap"
              alignItems="center"
            >
              <ProductInstanceSummary
                productInstance={product}
                summaryComponents={[
                  ProductQuantityWithFulfillmentChip,
                  ProductAvailabilityIndicator,
                ]}
              />
              {adminView && (
                <Tooltip title="Toggle availability status">
                  <Switch
                    color="error"
                    checked={product.availabilityStatus === "Unavailable"}
                    onChange={(e) => {
                      const status = e.target.checked
                        ? "Unavailable"
                        : "Available";
                      AdvancedRequestService.updateProductAvailabilityStatus(
                        advancedRequest.id,
                        product.id,
                        status
                      )
                        .then(() => {
                          setAdvancedRequest((prev) => {
                            const updatedProducts = prev.requestProducts.map(
                              (p) =>
                                p.id === product.id
                                  ? { ...p, availabilityStatus: status }
                                  : p
                            );
                            return {
                              ...prev,
                              requestProducts: updatedProducts,
                            };
                          });
                        })
                        .catch((error) => {
                          addErrorAlert(
                            "Failed to update product availability status",
                            error
                          );
                        });
                    }}
                    inputProps={{
                      "aria-label": `toggle availability for ${product.name}`,
                    }}
                  />
                </Tooltip>
              )}
            </Grid>
          ))}
          {advancedRequest?.requestProducts.some(
            (product) => product.availabilityStatus === "Unavailable"
          ) && (
            <Alert
              severity="warning"
              sx={{ mt: 2 }}
              data-testid="availability-warning"
            >
              Some products in this request are marked as unavailable. They are
              either out of season or otherwise unavailable and are unlikely to
              be fulfilled in this request.
            </Alert>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
