import {
  Autocomplete,
  CircularProgress,
  ListItemText,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAlerts } from "../../context/AlertContext";
import PaymentMethodService from "../../service/PaymentMethodService";
import { FormTextField } from "./FormTextField";

export default function PaymentMethodAutocomplete({
  field: { name, value, onChange, onBlur },
  form: { touched, errors },
  label,
  ...props
}) {
  const { addErrorAlert } = useAlerts();
  const [loading, setLoading] = useState(false);
  const [displayText, setDisplayText] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [filteredMethods, setFilteredMethods] = useState([]);

  useEffect(() => {
    setLoading(true);
    PaymentMethodService.findAll()
      .then((response) => {
        setPaymentMethods(response);
        setFilteredMethods(response);
      })
      .catch((error) => {
        addErrorAlert("Error fetching payment methods", error);
      })
      .finally(() => setLoading(false));
  }, [addErrorAlert]);

  useEffect(() => {
    if (props.value || !displayText) {
      setFilteredMethods(paymentMethods);
    } else {
      const filtered = paymentMethods.filter((method) =>
        method.name.toLowerCase().includes(displayText.toLowerCase())
      );
      const nonFiltered = paymentMethods.filter(
        (method) =>
          !method.name.toLowerCase().includes(displayText.toLowerCase())
      );
      setFilteredMethods([...filtered, ...nonFiltered]);
    }
  }, [displayText, paymentMethods, props.value]);

  return (
    <Autocomplete
      label={label}
      size="small"
      fullWidth
      id={name}
      data-testid={name}
      loading={loading}
      inputValue={displayText}
      autoHighlight
      onInputChange={(e, newInputValue) => {
        setDisplayText(newInputValue);
      }}
      noOptionsText={
        displayText ? "No payment methods found" : "Start typing to search"
      }
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      getOptionLabel={(option) => {
        if (option.id) {
          return option.name;
        } else {
          return option;
        }
      }}
      options={filteredMethods}
      renderOption={(props, methodOption) => {
        if (methodOption.id) {
          return (
            <MenuItem {...props} key={methodOption.id}>
              <ListItemText
                primary={methodOption.name}
                secondary={
                  methodOption.fee
                    ? `+ %${parseFloat(methodOption.fee * 100).toFixed(1)}`
                    : undefined
                }
              />
            </MenuItem>
          );
        }
      }}
      {...props}
      name={name}
      value={value}
      onChange={(e, newValue) => {
        onChange({ target: { name: name, value: newValue } });
      }}
      renderInput={(params) => (
        <FormTextField
          {...params}
          field={{
            name,
            value,
            onChange: (e) => {
              setDisplayText(e.target.value);
            },
            onBlur,
          }}
          form={{ touched, errors }}
          label={label}
          required={props.required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
