import axios from "axios";

export const AUTH_BASE_URL = process.env.REACT_APP_API_PATH + "/auth";

const authAxiosInstance = axios.create();
authAxiosInstance.defaults.withCredentials = true;

export const AuthenticationService = {
  login: (email, password) =>
    authAxiosInstance
      .post(AUTH_BASE_URL + "/login", { email, password })
      .then((r) => r.data),
  signup: (email, password) =>
    authAxiosInstance
      .post(AUTH_BASE_URL + "/newUser", { email, password })
      .then((r) => r.data),
  sendPasswordResetEmail: (email) =>
    authAxiosInstance.put(AUTH_BASE_URL + "/forgot-password", { email }),
  resetPasswordLogin: (password, verificationCode) =>
    authAxiosInstance
      .post(AUTH_BASE_URL + "/reset-password-login", {
        password,
        verificationCode,
      })
      .then((r) => r.data),
  refreshToken: () =>
    authAxiosInstance.get(AUTH_BASE_URL + "/refresh").then((r) => r.data),
  // Note this uses the base axios instance, not the authAxiosInstance - this is because the user is logged in
  changePassword: (password) =>
    axios
      .post(AUTH_BASE_URL + "/reset-password", {
        password,
      })
      .then((r) => r.data),
  // Note this uses the base axios instance, not the authAxiosInstance - this is because the user is logged in
  resendVerificationEmail: () =>
    axios.get(AUTH_BASE_URL + "/resend-verification-email"),
  verifyEmail: (verificationCode) =>
    authAxiosInstance
      .get(AUTH_BASE_URL + "/verify-email", { params: { verificationCode } })
      .then((r) => r.data),
  logout: () => authAxiosInstance.get(AUTH_BASE_URL + "/logout"),
};
