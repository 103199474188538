import { EditNote } from "@mui/icons-material";
import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import PaginatedSearchResults from "../../../common/components/data-display/PaginatedSearchResults";
import TermsConditionsService from "../../../common/service/TermsConditionsService";
import TermsConditionsSearchCriteria from "./TermsConditionsSearchCriteria";
import TermsConditionsSummary from "./TermsConditionsSummary";
const ManageTermsConditions = () => {
  const defaultSearchCriteria = {
    page: 1,
    itemsPerPage: 10,
    typeOfTerms: "",
  };
  return (
    <Container sx={{ pt: 1.5 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h2" gutterBottom sx={{ width: "fit-content" }}>
          Manage Terms & Conditions
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: 2,
          maxWidth: 600,
        }}
      >
        <Button
          component={Link}
          to={{ pathname: "edit-buyer" }}
          variant="contained"
          startIcon={<EditNote />}
        >
          Edit Buyer T&C
        </Button>
        <Button
          component={Link}
          to={{ pathname: "edit-supplier" }}
          variant="contained"
          startIcon={<EditNote />}
        >
          Edit Supplier T&C
        </Button>
      </Box>

      <Box sx={{ maxWidth: 600, marginLeft: "auto", marginRight: "auto" }}>
        <PaginatedSearchResults
          fetchSearchResults={TermsConditionsService.getTermsConditionsList}
          ResultDisplayComponent={TermsConditionsSummary}
          defaultSearchCriteria={defaultSearchCriteria}
          SearchCriteriaComponent={TermsConditionsSearchCriteria}
        />
      </Box>
    </Container>
  );
};

export default ManageTermsConditions;
