import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { FastField, useFormikContext } from "formik";
import React from "react";
import AttributeFormField from "../../../common/components/form/AttributeFormField";

export default function PriceGroupAttributeSelector({ baseProduct }) {
  const { values, setFieldValue } = useFormikContext();
  const attributesForPricing =
    baseProduct?.attributes?.filter((attr) => attr.canDeterminePrice) || [];
  const stillAvailableAttributes =
    baseProduct?.attributes
      ?.filter(
        (attr) =>
          attr.canDeterminePrice &&
          !values?.instanceAttributes?.some(
            (ia) => ia.attributeId === attr.attributeId
          )
      )
      .map((attr) => ({
        value: attr.attributeId,
        label: attr.attributeName,
      })) || [];

  React.useEffect(() => {
    // Automatically add the only available attribute to the price group
    if (
      attributesForPricing.length === 1 &&
      stillAvailableAttributes.length === 1
    ) {
      const newAttribute = attributesForPricing[0];
      setFieldValue("instanceAttributes", [
        ...values.instanceAttributes,
        {
          attributeId: newAttribute.attributeId,
          attributeName: newAttribute.attributeName,
          attributeValue: "",
          attributeType: newAttribute.attributeType,
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={1.5} sx={{ mb: 1 }}>
      <Grid xs={12}>
        {stillAvailableAttributes.length === 0 ? (
          <Typography variant="body2">
            All attributes that determine price have been selected
          </Typography>
        ) : (
          <FormControl fullWidth size={"small"}>
            <InputLabel id={"price-attr-select-label"}>
              Select an attribute to determine price
            </InputLabel>
            <Select
              id="price-attr-select"
              data-testid="price-attr-select"
              labelId={"price-attr-select-label"}
              label="Select an attribute to determine price"
              onChange={(e) => {
                const newAttribute = baseProduct.attributes.find(
                  (attr) => attr.attributeId === e.target.value
                );
                setFieldValue("instanceAttributes", [
                  ...values.instanceAttributes,
                  {
                    attributeId: newAttribute.attributeId,
                    attributeName: newAttribute.attributeName,
                    attributeValue: "",
                    attributeType: newAttribute.attributeType,
                  },
                ]);
              }}
              value={""}
            >
              {stillAvailableAttributes.map((attr) => (
                <MenuItem key={attr.value} value={attr.value}>
                  {attr.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Grid>
      {/* Allow user to select from any attribute that determines price
         and that isn't already in this price group */}
      {values?.instanceAttributes?.map((attr, idx) => (
        <Grid xs={12} key={idx} data-testid={`pricing-attribute-${idx}`}>
          <FastField
            key={idx}
            component={AttributeFormField}
            name={`instanceAttributes.[${idx}].attributeValue`}
            label={attr.attributeName}
            attribute={attr}
            required
          />
        </Grid>
      ))}
    </Grid>
  );
}
