import { Storefront } from "@mui/icons-material";
import { Avatar, Box, Button, Link, Typography } from "@mui/material";
import React from "react";
import { useAuthContext } from "../../context/AuthContext";
import SelectBusinessDialog from "../dialogs/SelectBusinessDialog";

export default function SelectableBusinessDisplay({
  selectedBusiness,
  setSelectedBusiness,
  buyersOnly,
  suppliersOnly,
  onlyUsersBusinesses,
  allowChangingDefault,
  forceSelection = false,
  size = "small",
  ...props
}) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const {
    supplierBusinesses,
    buyerBusinesses,
    setDefaultSupplier,
    setDefaultBuyer,
  } = useAuthContext();

  React.useEffect(() => {
    const businesses = buyersOnly ? buyerBusinesses : supplierBusinesses;
    // If there is only one business, select it
    if (!selectedBusiness && businesses.length === 1) {
      setSelectedBusiness(businesses[0]);
    } else if (!selectedBusiness && businesses.length > 1 && forceSelection) {
      setDialogOpen(true);
    }
  }, [
    forceSelection,
    buyerBusinesses,
    buyersOnly,
    selectedBusiness,
    setSelectedBusiness,
    supplierBusinesses,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "fit-content",

        ...props.sx,
      }}
    >
      {props.label && (
        <Typography
          variant="overline"
          sx={{ fontSize: size === "large" ? "120%" : undefined }}
        >
          {props.label}
        </Typography>
      )}
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Avatar
          alt={selectedBusiness?.name || `No Business Selected`}
          src={selectedBusiness?.logoPath}
          sx={{ height: 36, width: 36, mr: 2 }}
        >
          <Storefront />
        </Avatar>
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontSize: size === "large" ? "130%" : undefined }}
          >
            {selectedBusiness?.name || `No Business Selected`}
          </Typography>
          {(supplierBusinesses.length > 1 || !onlyUsersBusinesses) && (
            <Link
              variant="caption"
              component={Button}
              onClick={() => setDialogOpen(true)}
              href="#"
              sx={{ fontSize: size === "large" ? "100%" : undefined }}
            >
              Change Business
            </Link>
          )}
        </Box>
      </Box>
      <SelectBusinessDialog
        open={(!selectedBusiness && forceSelection) || dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSelect={(businessToSelect, makeDefault) => {
          // If the user is an admin, we don't want to set the default supplier
          if (makeDefault && suppliersOnly) {
            setDefaultSupplier(businessToSelect);
          } else if (makeDefault && buyersOnly) {
            setDefaultBuyer(businessToSelect);
          }
          setSelectedBusiness(businessToSelect);
          setDialogOpen(false);
        }}
        allowChangingDefault={
          onlyUsersBusinesses &&
          allowChangingDefault &&
          (buyersOnly || suppliersOnly)
        }
        currentSelectedBusiness={selectedBusiness}
        businessAutocompleteProps={{
          suppliersOnly,
          onlyUsersBusinesses,
          buyersOnly,
        }}
      />
    </Box>
  );
}
